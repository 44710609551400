import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import draftToHtml from "draftjs-to-html";
import {useIntl} from "gatsby-plugin-intl";

const Help = () => {
    const intl = useIntl()
    let data = useStaticQuery(graphql`
        query FaqQuery{
            allFaq {
              nodes {
                title
                faq_id
                value
                lang
              }
            }    
        }
  ` )
    data = data.allFaq.nodes.filter(function (item) {
        return item.lang === intl.locale
    })

    return (
        <div className={"img-bg"}>
            <Layout>
                <SEO title="Help" />
                <div id="page-content-wrapper">
                    <h2 className="my-1 txt-primary text-center">
                        <b style={{color: '#ad1457'}}>{intl.formatMessage({ id: "help" })}</b>
                    </h2>
                    <br/>
                    <div id="accordion" style={{ width: "100%", marginBottom: "100px" }}>
                        {
                            data.map(function(val, i){
                                return (
                                    <div key={"section-" + i}>
                                        <div className="card mb-1 shadow">
                                            <div className="card-header" id={"heading-" + i} data-toggle="collapse"
                                                 data-target={"#collapse-" + i} aria-expanded="true"
                                                 aria-controls={"collapse-" + i}>
                                                <h5 className="mb-0">
                                                    {val.title}
                                                </h5>
                                            </div>

                                            <div id={"collapse-" + i} className="collapse" aria-labelledby={"heading-" + i}
                                                 data-parent="#accordion">
                                                <div className="card-body">
                                                    <div dangerouslySetInnerHTML={{__html: (draftToHtml(JSON.parse(val.value)))}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* <Link to="/">Go back to the homepage</Link> */}
            </Layout>
        </div>
    )
}

export default Help
